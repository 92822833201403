import axios from "axios";
import CommonHelper from "@/helpers/common.helper.js";
import moment from "moment";
let today = moment();

/**
 * Fetches reservations within a specified date range.
 *
 * @param {string} fromDate - The start date for the reservation query in YYYY-MM-DD format.
 * @param {string} toDate - The end date for the reservation query in YYYY-MM-DD format.
 * @returns {Promise<Array|Null>} A promise that resolves to an array of reservations if the request is successful, or null if the request fails.
 */
const getReservations = async (fromDate, toDate) => {
  const url = `${CommonHelper.getApiURL()}/reservation/overview?fromDate=${fromDate}&toDate=${toDate}`;

  const { data, status } = await axios.get(url);
  if (status == 200) {
    let reservations = data.reservations ?? [];
    reservations.forEach(setCancellationProps);
    return reservations;
  } else {
    return null;
  }
};
/**
 * Cancels reservations for the given date IDs.
 *
 * @param {Array<number>} dateIds - An array of date IDs for which reservations need to be canceled.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
const cancelReservations = async (dateIds) => {
  const url = `${CommonHelper.getApiURL()}/reservation/cancel`;
  const { data } = await axios.post(url, dateIds);
  return data;
};
/**
 * Sets cancellation properties on the given reservation object.
 *
 * @param {Object} reservation - The reservation object to set properties on.
 * @param {Array} reservation.dates - Array of reservation dates.
 * @param {Array} reservation.guestsWithDates - Array of guests with their respective dates.
 * @param {Object} reservation.guestsWithDates[].employee - Employee object associated with the guest.
 * @param {string|null} reservation.guestsWithDates[].employee.id - Employee ID.
 * @param {string|null} reservation.guestsWithDates[].employee.firstName - Employee first name.
 * @param {string|null} reservation.guestsWithDates[].employee.lastName - Employee last name.
 * @param {boolean} reservation.hasCancelledDates - Indicates if there are any cancelled dates.
 * @param {boolean} reservation.hasCancallabeDates - Indicates if there are any cancellable dates.
 * @param {boolean} reservation.allDatesCancelled - Indicates if all dates are cancelled.
 * @param {boolean} reservation.guestsWithDates[].employee.hasCancallabeDates - Indicates if the guest has any cancellable dates.
 * @param {boolean} reservation.guestsWithDates[].employee.hasCancelledDates - Indicates if the guest has any cancelled dates.
 * @param {boolean} reservation.guestsWithDates[].employee.allDatesCancelled - Indicates if all dates for the guest are cancelled.
 */
const setCancellationProps = (reservation) => {
  let dates = getReservationDates(reservation);
  const cancelledDates = dates.filter(dateIsCancelled).length;

  const cancellableDates = dates.filter(dateIsCancellable).length;

  reservation.hasCancelledDates = cancelledDates > 0;
  reservation.hasCancallabeDates = cancellableDates > 0;
  reservation.allDatesCancelled = dates.length == cancelledDates;
  reservation?.guestsWithDates?.forEach((gwd) => {
    let guestHasCancallableDates = false;
    let guestHasCancelledDates = false;
    let allDatesCancelled = true;
    gwd.dates.forEach((d) => {
      if (dateIsCancellable(d)) {
        guestHasCancallableDates = true;
      }
      if (dateIsCancelled(d)) {
        guestHasCancelledDates = true;
      } else {
        allDatesCancelled = false;
      }
    });
    if (gwd.employee === null) gwd.employee = {};
    if (gwd.employee.id === null) {
      gwd.employee.id = Math.random().toString(36).substr(2, 9);
    }
    if (gwd.employee.firstName === null) {
      gwd.employee.firstName = "";
    }
    if (gwd.employee.lastName === null) {
      gwd.employee.lastName = "";
    }

    gwd.employee.hasCancallabeDates = guestHasCancallableDates;
    gwd.employee.hasCancelledDates = guestHasCancelledDates;
    gwd.employee.allDatesCancelled = allDatesCancelled;
  });
};
/**
 * Extracts and flattens the dates from the guestsWithDates property of a reservation object.
 *
 * @param {Object} reservation - The reservation object containing guestsWithDates.
 * @param {Array} reservation.guestsWithDates - An array of objects, each containing a dates property.
 * @returns {Array} - A flattened array of dates.
 */
const getReservationDates = (reservation) => {
  const guestsWithDates = reservation.guestsWithDates;
  let dates = guestsWithDates
    .map((gwd) => {
      return gwd.dates;
    })
    .flat();
  return dates;
};
/**
 * Checks if the given date object has a status indicating it is cancelled or rejected.
 *
 * @param {Object} d - The date object to check.
 * @param {string} d.status - The status of the date.
 * @returns {boolean} - Returns true if the date is cancelled or rejected, otherwise false.
 */
const dateIsCancelled = (d) => {
  let isCancelledDate =
    d.status != "" &&
    (d.status.toLowerCase().includes("cancel") ||
      d.status.toLowerCase().includes("rejected"));

  return isCancelledDate;
};
/**
 * Determines if a given date is cancellable.
 *
 * A date is considered cancellable if:
 * - Its status is not empty and does not include "cancel" or "rejected".
 * - The date is in the future.
 *
 * @param {Object} d - The date object to check.
 * @param {string} d.status - The status of the date.
 * @param {string} d.date - The date string to check.
 * @returns {boolean} - Returns true if the date is cancellable, false otherwise.
 */
const dateIsCancellable = (d) => {
  let isCancelledDate =
    d.status != "" &&
    (d.status.toLowerCase().includes("cancel") ||
      d.status.toLowerCase().includes("rejected"));

  let isInFuture = moment(d.date).isAfter(today);
  let isCancellabeDate = !isCancelledDate && isInFuture;

  return isCancellabeDate;
};

// Logic for fields that are required if another field is filled
const isFieldMandatory = (currentField, customFieldFormData) => {
  let mandatoryByInput = false;

  let companyInfo = CommonHelper.getCompanyInfoSync();

  /*
  // Spie: If projectnumber is filed, follownumber is required
  if (companyInfo?.company?.code == "SPI") {
    let projectNumber = customFieldFormData["Project__c"];
    let projectNumberFilled =
      projectNumber !== undefined && projectNumber.length > 0;
    mandatoryByInput = projectNumberFilled && currentField.name === "Order__c";
  }
  */

  return currentField.mandatory || mandatoryByInput;
};

export default {
  getReservations,
  cancelReservations,
  setCancellationProps,
  dateIsCancellable,
  isFieldMandatory
};
