<template>
  <div>
    <article
      style="z-index: 5"
      class="message is-medium"
      :class="`is-${level}`"
      v-if="show"
    >
      <div class="message-header">
        <p v-html="title"></p>
        <button
          class="delete"
          @click="this.$emit('toggle', false)"
          aria-label="delete"
        ></button>
      </div>
      <div class="message-body">
        <p v-html="message"></p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Er ging iets mis"
    },
    message: {
      type: String,
      default: "Met het inloggen ofzo man"
    },
    level: {
      type: String,
      default: "info"
    },
    show: {
      type: Boolean,
      default: true
    },
    autoHide: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (this.autoHide) {
      setTimeout(() => {
        this.$emit("toggle", false);
      }, 10 * 1000);
    }
  },
  methods: {
    name() {}
  }
};
</script>

<style lang="scss" scoped></style>
